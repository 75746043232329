import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faIdCard, faLock, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BenefitType } from 'src/app/core/models/BenefitType';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectTenantNome, selectUserType } from 'src/app/core/store/selectors';
import { SimpleBeneficiary } from '../../../../core/models/SimpleBeneficiary';

@Component({
  selector: 'app-tabela-alteracao-cadastral',
  templateUrl: './tabela-alteracao-cadastral.component.html',
  styleUrls: ['./tabela-alteracao-cadastral.component.scss'],
})
export class TabelaAlteracaoCadastralComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  dataSource = new MatTableDataSource<SimpleBeneficiary>();

  displayedColumns = ['name', 'birthDate', 'kinship', 'cardNumber', 'actions'];

  userType$ = this.store.select(selectUserType);

  tenantNome$ = this.store.select(selectTenantNome);

  faUserPlus = faUserPlus;
  faUserEdit = faUserEdit;
  faEye = faEye;
  faLock = faLock;
  faIdCard = faIdCard;
  benefitTypeControl = new FormControl<BenefitType | null>(null);

  destroyed$ = new Subject<void>();

  benefitTypes?: BenefitType[];

  pesquisaCtrl = new FormControl('');

  constructor(
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private loaderService: LoaderService,
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    public config: ConfigService
  ) {}

  ngOnInit(): void {
    this.loadBenefitTypes();
    this.registerSearch();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  shouldShowAddDependentButton(beneficiary: Beneficiary): boolean {
    return beneficiary.kinship === 'Titular';
  }

  getEditBeneficiaryRoute(beneficiary: Beneficiary): string {
    let url = '/dados-cadastrais';
    const isTitular = beneficiary.kinship === 'Titular';
    url += `/alterar-${isTitular ? 'titular' : 'dependente'}`;

    url += `/${beneficiary.idBeneficiary}`;

    return url;
  }

  getEditPlanRoute(beneficiary: Beneficiary): string {
    let url = '/dados-cadastrais';
    const isTitular = beneficiary.kinship === 'Titular';
    url += `/alterar-plano-${isTitular ? 'titular' : 'dependente'}`;

    url += `/${beneficiary.idBeneficiary}`;

    return url;
  }

  private loadBeneficiaries(): void {
    this.dataSource.data = [];
    const benefitType = this.benefitTypeControl.value;

    this.loaderService.show('Carregando beneficiários...');
    this.beneficiaryService
      .getBeneficiaries({ benefitType: benefitType?.type, idClient: benefitType?.idClient, flat: benefitType?.flat })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.dataSource.data = response;
      })
      .add(() => {
        this.loaderService.hide();
      });
  }

  private loadBenefitTypes(): void {
    this.loaderService.show('Carregando tipos de benefícios...');

    this.beneficiaryService
      .getBenefitTypes(false)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((benefitTypes) => {
        this.benefitTypes = benefitTypes;
        this.loadParams();

        if (!this.benefitTypeControl.value) {
          this.benefitTypeControl.setValue(this.benefitTypes[0]);
        }

        this.registerOnBenefitTypeChanges();
        this.loadBeneficiaries();
      })
      .add(() => {
        this.loaderService.hide();
      });
  }

  private registerOnBenefitTypeChanges(): void {
    this.benefitTypeControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((benefitType) => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { benefitType },
      });

      this.loadBeneficiaries();
    });
  }

  private loadParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(({ idClient }) => {
      if (!idClient) {
        return;
      }

      const benefitType = this.benefitTypes?.find((b) => b.idClient === idClient);

      if (!benefitType) {
        return;
      }

      this.benefitTypeControl.setValue(benefitType);
    });
  }

  private registerSearch(): void {
    this.pesquisaCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((search) => {
      this.dataSource.filter = search ?? '';
    });

    this.dataSource.filterPredicate = (data: SimpleBeneficiary, filter: string) => {
      filter = filter.replace(/[\/]/g, '-');

      return JSON.stringify(data).toLowerCase().includes(filter.toLowerCase());
    };
  }
}
