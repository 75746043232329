import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { addDays, differenceInCalendarDays, format, isAfter, isBefore, isValid, parse } from 'date-fns';
import { BehaviorSubject, Subject, interval } from 'rxjs';
import { delay, distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs/operators';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BeneficiaryRule } from 'src/app/core/models/BeneficiaryRule';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { CepService } from 'src/app/core/services/cep';
import { ConfigService } from 'src/app/core/services/config';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { CustomValidators } from 'src/app/core/utils/custom-validators';
import {
  getAgeByBirthDate,
  getDDDFromPhoneNumber,
  getPhoneNumberWithoutDDD,
  onlyNumbers,
} from 'src/app/core/utils/helpers';
import { BeneficiaryKinship } from '../../models/BeneficiaryKinship';
import { Contract } from '../../models/Contract';
import { ClientService } from '../../services/client';
import { KinshipService } from '../../services/kinship';
import { BeneficiaryType } from '../../types/BeneficiaryType';

@Component({
  selector: 'app-beneficiary-form',
  templateUrl: './beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.scss'],
})
export class BeneficiaryFormComponent implements OnInit, OnDestroy {
  @Input() type!: BeneficiaryType;

  @Input() mode!: 'Adicionar' | 'Editar';

  @Input() beneficiaryId!: string;

  @Input() beneficiaryParentId!: string;

  @Output() saved = new EventEmitter<Beneficiary>();

  @Output() canceled = new EventEmitter();

  formGroup!: FormGroup;

  effectiveDate?: string;

  kinships!: BeneficiaryKinship[];

  contracts!: Contract[];
  policys!: Contract[];

  fluxoVigencia$ = new BehaviorSubject<'ListaProtheus' | 'RecemVinculado'>('ListaProtheus');

  rules: BeneficiaryRule[] = [];

  private _beneficiary?: Beneficiary;

  private destroyed$ = new Subject<void>();
  showContract = true;
  showSubContract = false;
  constructor(
    private formBuilder: FormBuilder,
    private cepService: CepService,
    private notification: NotificationService,
    private kinshipService: KinshipService,
    private loader: LoaderService,
    private clientService: ClientService,
    private beneficiaryService: BeneficiaryService,
    public config: ConfigService
  ) {}

  get selectedKinship(): string {
    return this.formGroup.get('kinship')?.value ?? '';
  }

  get beneficiary(): Beneficiary | undefined {
    return this._beneficiary;
  }

  @Input() set beneficiary(_beneficiary: Beneficiary | undefined) {
    this._beneficiary = _beneficiary;

    if (_beneficiary) {
      interval(0)
        .pipe(delay(10), first())
        .subscribe(() => {
          this.fillForm();
        });
    }
  }

  ngOnInit(): void {
    this.showContract = this.config.getConfiguration('BENEFICIARY_SHOW_CONTRACT');
    this.showSubContract = this.config.getConfiguration('BENEFICIARY_SHOW_SUB_CONTRACT');
    this.buildForm();
    this.loadContracts();
    this.loadEffectiveDates();
    this.loadRules();
    this.registerEffectiveDateDisableState();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }
    const beneficiary =
      this.mode === 'Editar' ? this.getUpdateBeneficiaryPayload() : this.getCreateBeneficiaryPayload();

    this.saved.emit(beneficiary);
  }

  loadCep(): void {
    const cepFormControl = this.formGroup.get('cep');
    const currentCep = cepFormControl?.value;

    if (cepFormControl?.invalid || !currentCep) {
      return;
    }

    this.cepService.getCepData(currentCep).subscribe((cepData) => {
      this.formGroup.patchValue({
        address: cepData.logradouro,
        district: cepData.bairro,
        city: cepData.localidade,
        uf: cepData.uf,
        ibgeCityCode: cepData.ibge,
      });
    });
  }

  private loadContracts(): void {
    if (this.type !== 'Titular' || this.mode !== 'Adicionar') {
      return;
    }

    this.loader.show('Carregando contratos');
    this.clientService
      .getContracts()
      .subscribe({
        next: (contracts) => {
          this.contracts = contracts;
          this.policys = this.removerContratosDuplicados(contracts);
        },
        error: () => {
          this.showNoPossibleAddBeneficiariesPopup({
            title: 'Você não pode adicionar uma nova família.',
            text: 'Não há nenhum contrato disponível',
          });
        },
      })
      .add(() => {
        this.loader.hide();
      });
  }

  // Função para remover objetos duplicados
  removerContratosDuplicados(array: Contract[]): Contract[] {
    return array.filter(
      (objeto, indice, array) => indice === array.findIndex((obj) => obj.codeContract === objeto.codeContract)
    );
  }

  public getSubContractsForContract(contractCode: string): Contract[] {
    if (contractCode) {
      return this.contracts.filter((c) => c.codeContract == contractCode);
    }

    return [];
  }

  private showNoPossibleAddBeneficiariesPopup({ title, text }: { title: string; text: string }): void {
    this.notification
      .confirm({
        title,
        text,
        confirmButtonText: 'Voltar para listagem',
        showCancelButton: false,
        showCloseButton: false,
        allowOutsideClick: false,
      })
      .subscribe(() => {
        this.canceled.emit();
      });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      cpf: [null, [Validators.required, CustomValidators.isValidCpf()]],
      rg: [null, Validators.required],
      gender: [null, Validators.required],
      maritalStatus: [null, Validators.required],
      weddingDate: [null, CustomValidators.dateBeforeToday('ddMMyyyy')],
      birthDate: [null, [Validators.required, CustomValidators.dateBeforeToday('ddMMyyyy')]],
      motherName: [null, Validators.required],
      preferredName: [null],
      email: [null, Validators.required],
      homePhone: [null],
      cellNumber: [null, Validators.required],
      cep: [null, Validators.required],
      address: [null, Validators.required],
      addressNumber: [null, [Validators.required, Validators.maxLength(6)]],
      addressComplement: [null],
      district: [null, Validators.required],
      city: [{ value: null, disabled: true }, Validators.required],
      uf: [{ value: null, disabled: true }, Validators.required],
      ibgeCityCode: [null],
      effectiveDate: [null, Validators.required],
      ruleCode: [null],
    });

    if (!this.config.getConfiguration('CAN_EDIT_BENEFICIARY_CELLPHONE')) {
      this.formGroup?.controls.cellNumber?.disable();
    }

    if (!this.config.getConfiguration('CAN_EDIT_BENEFICIARY_EMAIL')) {
      this.formGroup?.controls.email?.disable();
    }

    if (this.mode === 'Editar') {
      this.createControl('cardNumber');
      this.createControl('cardNumberOdonto');
      this.disableControls([
        'name',
        'cpf',
        'rg',
        'gender',
        'maritalStatus',
        'weddingDate',
        'birthDate',
        'motherName',
        'cardNumber',
        'cardNumberOdonto',
      ]);
      this.removeRequired();
      return;
    }

    this.createControls(['issuer', 'kinship']);
    this.setControlsRequired(['issuer', 'kinship']);
    this.registerKinshipLoad();

    if (this.type === 'Titular') {
      if (this.showContract) {
        this.createControl('contract');
        this.setControlRequired('contract');
      }

      if (this.showSubContract) {
        this.createControl('contract');
        this.setControlRequired('contract');
        this.createControl('subContract');
        this.setControlRequired('subContract');
      }

      this.formGroup.get('kinship')?.setValue('Titular');
    }

    this.createControls(['issuer', 'kinship']);
    this.setControlsRequired(['issuer', 'kinship']);

    this.formGroup.get('kinship')?.valueChanges.subscribe(() => {
      this.formGroup.get('weddingDate')?.reset();
    });
    this.removeRequired();
    this.formGroup.valueChanges
      .pipe(
        filter(() => this.config.getConfiguration('DATA_DE_VIGENCIA_EDITAVEL_PARA_RECEM_CASADO_RECEM_NASCIDO')),
        filter(() => this.type === 'Dependente'),
        map((formValue) => {
          if (formValue.kinship === 'ESPOSO(A)') {
            return formValue.weddingDate;
          }

          if (formValue.kinship === 'FILHO(A)') {
            return formValue.birthDate;
          }

          return '';
        }),
        filter((v) => v?.length === 8),
        map((dateStr) => parse(dateStr, 'ddMMyyyy', new Date())),
        filter((date) => isValid(date)),
        filter((date) => !isBefore(new Date(), date)),
        map((date) => differenceInCalendarDays(new Date(), date) <= 30),
        map((isRecent: boolean) => (isRecent ? 'RecemVinculado' : 'ListaProtheus')),
        distinctUntilChanged()
      )
      .subscribe((v: any) => this.fluxoVigencia$.next(v));
  }

  private removeRequired() {
    const fiels = this.config.getConfiguration('FORM_BENEFICIARIO_NOT_REQUIRED').fields as string[];

    fiels.forEach((controle: string) => {
      this.formGroup.get(controle)?.clearValidators();
      this.formGroup.get(controle)?.updateValueAndValidity();
    });
  }

  private disableControls(controlNames: string[]): void {
    controlNames.forEach(this.disableControl.bind(this));
  }

  private disableControl(controlName: string): void {
    this.formGroup.get(controlName)?.disable();
  }

  private enableControl(controlName: string): void {
    this.formGroup.get(controlName)?.enable();
  }

  private createControls(controlNames: string[]): void {
    controlNames.forEach(this.createControl.bind(this));
  }

  private createControl(controlName: string): void {
    this.formGroup.registerControl(controlName, this.formBuilder.control(null));
  }

  private setControlRequired(controlName: string): void {
    this.formGroup.get(controlName)?.setValidators(Validators.required);
  }

  private setControlsRequired(controlNames: string[]): void {
    controlNames.forEach(this.setControlRequired.bind(this));
  }

  private getUpdateBeneficiaryPayload(): Beneficiary {
    const form = this.formGroup.getRawValue();

    return {
      ...this.beneficiary!,
      name: form.name,
      cpf: onlyNumbers(form.cpf),
      rg: onlyNumbers(form.rg),
      gender: form.gender,
      maritalStatus: form.maritalStatus,
      birthDate: this.formatDate(form.birthDate, true),
      motherName: form.motherName,
      preferredName: form.preferredName,
      email: form.email,
      cep: form.cep,
      address: form.address,
      addressNumber: form.addressNumber,
      addressComplement: form.addressComplement,
      district: form.district,
      city: form.city,
      uf: form.uf,
      dddCell: getDDDFromPhoneNumber(form.cellNumber),
      cellNumber: getPhoneNumberWithoutDDD(form.cellNumber),
      dddHomePhone: getDDDFromPhoneNumber(form.homePhone),
      homePhone: getPhoneNumberWithoutDDD(form.homePhone),
      ibgeCityCode: form.ibgeCityCode,
      ruleCode: form.ruleCode,
    };
  }

  private getCreateBeneficiaryPayload(): Beneficiary {
    const form = this.formGroup.getRawValue();

    let effectiveDate = '';

    if (form.effectiveDate && !form.effectiveDate?.includes('/')) {
      effectiveDate = format(parse(form.effectiveDate, 'ddMMyyyy', new Date()), 'dd/MM/yyyy');
    }

    return {
      idBeneficiary: undefined,
      idEmployee: undefined,
      name: form.name,
      cpf: onlyNumbers(form.cpf),
      rg: onlyNumbers(form.rg),
      issuer: form.issuer,
      gender: form.gender,
      maritalStatus: form.maritalStatus,
      birthDate: this.formatDate(form.birthDate, true),
      motherName: form.motherName,
      preferredName: form.preferredName,
      email: form.email,
      cep: form.cep,
      address: form.address,
      addressNumber: form.addressNumber,
      addressComplement: form.addressComplement,
      district: form.district,
      city: form.city,
      uf: form.uf,
      dddCell: getDDDFromPhoneNumber(form.cellNumber),
      cellNumber: getPhoneNumberWithoutDDD(form.cellNumber),
      dddHomePhone: getDDDFromPhoneNumber(form.homePhone),
      homePhone: getPhoneNumberWithoutDDD(form.homePhone),
      status: 'Ativo',
      type: this.type,
      cardNumber: form.cardNumber,
      cardNumberOdonto: form.cardNumberOdonto,
      kinship: form.kinship,
      weddingDate: this.formatDate(form.weddingDate, true),
      ibgeCityCode: form.ibgeCityCode,
      effectiveDate,
      ruleCode: form.ruleCode,
      healthPlan: {
        benefitType: '',
        accommodation: '',
        ans: '',
        coparticipation: false,
        coverage: '',
        entity: '',
        plan: '',
        provider: '',
        codeEntity: '',
        codeProvider: '',
        contract: form?.contract,
        subContract: form?.subContract,
      },
    };
  }

  private formatDate(dateString: string, reverse = false, separator = '-'): string {
    dateString = onlyNumbers(dateString);
    const date = dateString?.slice(0, 2);
    const month = dateString?.slice(2, 4);
    const year = dateString?.slice(4, 8);

    let result = [date, month, year].filter(Boolean);

    if (reverse) {
      result = result.reverse();
    }

    return result.join(separator);
  }

  private fillForm(): void {
    if (!this.beneficiary) {
      return;
    }

    this.formGroup.patchValue({
      name: this.beneficiary.name,
      cpf: this.beneficiary.cpf,
      rg: this.beneficiary.rg,
      gender: this.beneficiary.gender,
      maritalStatus: this.beneficiary.maritalStatus,
      birthDate: this.formatDate(this.beneficiary.birthDate, false, '/'),
      motherName: this.beneficiary.motherName,
      preferredName: this.beneficiary.preferredName,
      email: this.beneficiary.email,
      homePhone: `${this.beneficiary.dddHomePhone}${this.beneficiary.homePhone}`,
      cellNumber: `${this.beneficiary.dddCell}${this.beneficiary.cellNumber}`,
      cep: this.beneficiary.cep,
      address: this.beneficiary.address,
      addressNumber: this.beneficiary.addressNumber,
      addressComplement: this.beneficiary.addressComplement,
      district: this.beneficiary.district,
      city: this.beneficiary.city,
      uf: this.beneficiary.uf,
      cardNumber: this.beneficiary.cardNumber,
      cardNumberOdonto: this.beneficiary.cardNumberOdonto,
      ruleCode: this.beneficiary.ruleCode,
      ibgeCityCode: this.beneficiary.ibgeCityCode,
    });

    this.loadCep();
  }

  private loadKinships(): void {
    this.loader.show('Buscando graus de parentesco');
    this.kinshipService
      .getAll(this.getAge(), this.beneficiaryParentId)
      .subscribe((kinships) => {
        this.kinships = kinships;
        this.enableControl('kinship');
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private getAge(): number {
    const birthDateString = this.formGroup.get('birthDate')?.value;
    const birthDate = parse(birthDateString, 'ddMMyyyy', new Date());

    return getAgeByBirthDate(birthDate);
  }

  private registerKinshipLoad(): void {
    const birthDateControl = this.formGroup.get('birthDate');

    birthDateControl?.valueChanges
      .pipe(
        filter(() => this.type === 'Dependente'),
        filter(() => birthDateControl.value?.length === 8),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.loadKinships();
      });
  }

  private loadEffectiveDates(): void {
    this.loader.show('Buscando Vigências disponíveis...');
    this.beneficiaryService
      .getEffectiveDates(this.beneficiaryId)
      .subscribe((v) => {
        this.effectiveDate = v.effectiveDate;
        this.formGroup.get('effectiveDate')?.setValue(v.effectiveDate);
      })
      .add(() => this.loader.hide());
  }

  private loadRules(): void {
    if (this.type !== 'Titular') {
      return;
    }

    if (!this.config.getConfiguration('SHOULD_SHOW_RULE_FIELD_ON_BENEFICIARY_FORM')) {
      return;
    }

    this.loader.show('Buscando Contratos disponíveis...');
    this.beneficiaryService
      .loadRules()
      .subscribe((rules) => {
        this.rules = rules;
        this.setControlsRequired(['ruleCode']);
        this.formGroup.get('ruleCode')?.updateValueAndValidity();
      })
      .add(() => this.loader.hide());
  }

  private registerEffectiveDateDisableState(): void {
    const validator = CustomValidators.date('ddMMyyyy');

    this.fluxoVigencia$.pipe(takeUntil(this.destroyed$)).subscribe((fluxoVigencia) => {
      const effectiveDateControl = this.formGroup.get('effectiveDate');
      if (fluxoVigencia === 'ListaProtheus') {
        effectiveDateControl?.disable();
        effectiveDateControl?.removeValidators([validator, this.effectiveDateValidation]);
        effectiveDateControl?.setValue(this.effectiveDate);
        return;
      }

      effectiveDateControl?.addValidators([validator, this.effectiveDateValidation]);
      effectiveDateControl?.enable();
      effectiveDateControl?.setValue('');
    });
  }

  private effectiveDateValidation: ValidatorFn = ({ value }) => {
    if (value.length !== 8) {
      return null;
    }

    const dateParse = (_date: string) => parse(_date, 'ddMMyyyy', new Date());

    const kinship = this.formGroup.get('kinship')?.value;
    const weddingDate = this.formGroup.get('weddingDate')?.value;
    const birthDate = this.formGroup.get('birthDate')?.value;

    let minDate = '';

    if (kinship === 'ESPOSO(A)') {
      minDate = weddingDate;
    }

    if (kinship === 'FILHO(A)') {
      minDate = birthDate;
    }

    if (isBefore(dateParse(value), dateParse(minDate))) {
      return { notPermittedDate: `A data não pode ser menor que ${format(dateParse(minDate), 'dd/MM/yyyy')}` };
    }

    const maxDate = addDays(dateParse(minDate), 30);

    if (isAfter(dateParse(value), maxDate)) {
      return { notPermittedDate: `A data não pode ser maior que ${format(maxDate, 'dd/MM/yyyy')}` };
    }

    return null;
  };
}
